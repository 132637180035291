.layout {
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
}

.card-login {
    width: 320px;
    box-shadow: none !important;
}

.title-wrapper {
    padding-bottom: 20px;
}

.input {
    height: 52px;
    width: 100%;
}

.input:focus,
.input:hover,
.input:focus-within {
    border-color: rgb(168, 158, 158);
    box-shadow: none
}

.button-wrapper {
    padding-top: 24px;
}

.button {
    height: 52px !important;
    width: 100%;
}

.logo-wrapper {
    width: 320px;
    margin-bottom: -20px;
}

.cisp1-login-logo {
    height: 40px;
    margin-right: 20px;
}

.paulo-barcellos-login-logo {
    height: 140px;
    margin-bottom: -5px;
    clip-path: inset(0 0 10px 0);
}