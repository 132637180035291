.header-card {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 70px;
}

.header-card .ant-card-body {
    padding: 0;
    height: 100%;
}

.header-wrapper {
    width: 100%;
    height: 100%;
}

.content-wrapper {
    height: 100%;
    padding: 0 20px;
}

.menu-button {
    color: #323335;
}

.cisp1-logo {
    height: 40px;
}

.paulo-barcellos-logo {
    height: 110px;
    margin-bottom: -5px;
    clip-path: inset(0 0 10px 0);
}

.large-menu-item {
    font-size: 16px;
    padding: 12px 20px;
}