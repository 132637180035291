html,
body,
div,
span,
h1,
h2,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

body {
    background-color: #272626;
    line-height: 1;
}